aside {
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  max-width: 400px;
  background-color: var(--blue);
  transition: var(--aside-transition);
  color: var(--foreground-aside);
  padding-left: 2rem;
  padding-right: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

@media (min-width: 1920px) {
  aside {
    transform: translateX(0);
  }
}

.open {
  transform: translateX(0);
}

.open .settings-toggle__icon {
  display: none;
}

.open .settings-toggle__icon--close {
  display: unset;
}

.settings-toggle {
  color: inherit;
  background-color: var(--blue);
  cursor: pointer;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 1em;
  right: 0;
  transform: translateX(100%);
}

.settings-toggle__icon {
  height: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.settings-toggle__icon--close {
  display: none;
}

@media (min-width: 1920px) {
  .settings-toggle {
    display: none;
  }
}

.setting-controls {
  font-family: Arimo, sans-serif;
}

header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.profile-picture {
  background-image: var(--profile-picture-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.profile-picture__clip-image {
  box-sizing: border-box;
  width: 239px;
  height: 340px;
  padding-left: var(--clip-image-padding);
  display: block;
}

.profile-picture__clip-image--head {
  background-color: var(--red);
  clip-path: url("#head-path");
}

.profile-picture__clip-image--torso {
  background-color: var(--green);
  clip-path: url("#torso-path");
  position: absolute;
  top: 0;
  left: 0;
}

.profile-picture__clip-image:hover {
  padding-left: 0;
}

.name {
  border-top: solid;
  border-color: var(--yellow);
  width: 239px;
  text-align: center;
  margin-top: 1em;
  padding-top: .6em;
}

.social-media-container {
  width: 100%;
  max-width: 239px;
  justify-content: space-around;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.social-media-entry {
  flex-direction: column;
  transition: transform .2s;
  display: flex;
}

.social-media-entry__icon {
  height: 1.5em;
  transition: height .2s;
}

.social-media-entry__label {
  transform-origin: top;
  writing-mode: vertical-lr;
  animation: var(--social-media-animation);
}

.social-media-entry__label:link {
  color: var(--link);
  text-decoration: none;
}

.social-media-entry__label:visited {
  color: var(--visited);
}

.social-media-entry__label:before {
  content: "— ";
  display: inline-block;
}

.social-media-entry:focus-within .social-media-entry__label, .social-media-entry:hover .social-media-entry__label {
  text-decoration: underline;
}

.social-media-entry:focus-within .social-media-entry__icon, .social-media-entry:hover .social-media-entry__icon {
  transform: scale(var(--social-media-icon-hover-scale));
}

@keyframes dangling {
  0% {
    transform: rotate(-130deg);
  }

  10% {
    transform: rotate(70deg);
  }

  20% {
    transform: rotate(-40deg);
  }

  30% {
    transform: rotate(20deg);
  }

  40% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-2deg);
  }

  60% {
    transform: rotate(1deg);
  }

  70% {
    transform: rotate(.5deg);
  }

  80% {
    transform: rotate(-.25deg);
  }

  90% {
    transform: rotate(.1deg);
  }

  100% {
    transform: rotate(0);
  }
}

main {
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
}

.paragraph {
  text-align: justify;
  margin-left: 1rem;
  font-family: Arimo, sans-serif;
}

.interests {
  flex-wrap: wrap;
  gap: .5rem;
  margin-top: 2rem;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.interests__item {
  background-color: var(--foreground);
  color: var(--background);
  border: solid var(--foreground);
  border-radius: 6px;
  padding: .2em .5rem;
  font-size: .8rem;
}

.download-button {
  background-color: var(--red);
  color: var(--foreground);
  border-radius: .3rem;
  margin-top: 2rem;
  padding: .6rem;
  font-size: 1.5rem;
  text-decoration: none;
  display: inline-block;
}

.download-button:hover {
  text-decoration: underline;
}

footer {
  text-align: right;
  margin-top: 2rem;
}

.hackor {
  background-color: var(--code-background);
  color: var(--code-foreground);
  font-family: Cousine, monospace;
  font-size: 1.25rem;
}

.hackor__yellow {
  color: var(--code-yellow);
}

.hackor__blue {
  color: var(--code-blue);
}

.hackor__green {
  color: var(--code-green);
}

@font-face {
  font-family: Tinos;
  font-style: normal;
  font-weight: 400;
  src: url("tinos-v24-cyrillic_greek_latin-regular.d7a96215.eot");
  src: url("tinos-v24-cyrillic_greek_latin-regular.d7a96215.eot#iefix") format("embedded-opentype"), url("tinos-v24-cyrillic_greek_latin-regular.7da7e4f7.woff2") format("woff2"), url("tinos-v24-cyrillic_greek_latin-regular.ef845ca3.woff") format("woff"), url("tinos-v24-cyrillic_greek_latin-regular.45773ee3.ttf") format("truetype"), url("tinos-v24-cyrillic_greek_latin-regular.9108ad68.svg#Tinos") format("svg");
}

@font-face {
  font-family: Arimo;
  font-style: normal;
  font-weight: 400;
  src: url("arimo-v27-latin-regular.11534e37.eot");
  src: url("arimo-v27-latin-regular.11534e37.eot#iefix") format("embedded-opentype"), url("arimo-v27-latin-regular.5c4e2736.woff2") format("woff2"), url("arimo-v27-latin-regular.e48ad3ef.woff") format("woff"), url("arimo-v27-latin-regular.f53f02f1.ttf") format("truetype"), url("arimo-v27-latin-regular.92edb38b.svg#Arimo") format("svg");
}

@font-face {
  font-family: Cousine;
  font-style: normal;
  font-weight: 400;
  src: url("cousine-v25-latin-regular.48e7558c.eot");
  src: url("cousine-v25-latin-regular.48e7558c.eot#iefix") format("embedded-opentype"), url("cousine-v25-latin-regular.13620c54.woff2") format("woff2"), url("cousine-v25-latin-regular.1622794a.woff") format("woff"), url("cousine-v25-latin-regular.f37b82f9.ttf") format("truetype"), url("cousine-v25-latin-regular.a2932887.svg#Cousine") format("svg");
}

:root {
  --green: #06d6a0;
  --red: #ef476f;
  --yellow: #ffd166;
  --blue: #118ab2;
  --foreground: #073b4c;
  --background: floralwhite;
  --link: #7b270b;
  --visited: #4c1807;
  --foreground-aside: floralwhite;
  --code-foreground: #839496;
  --code-background: #fdf6e3;
  --code-yellow: #b58900;
  --code-orange: #cb4b16;
  --code-red: #dc322f;
  --code-magenta: #d33682;
  --code-violet: #6c71c4;
  --code-blue: #268bd2;
  --code-cyan: #2aa198;
  --code-green: #859900;
  --profile-picture-background-image: url("background.cf619313.svg");
  --clip-image-padding: 239px;
  --social-media-animation: dangling 1s;
  --aside-transition: transform .2s;
  --social-media-icon-hover-scale: 1.1;
}

:root [for="theme-switcher-option-system"]:after {
  content: " (light)";
}

@media (prefers-color-scheme: dark) {
  :root [for="theme-switcher-option-system"]:after {
    content: " (dark)";
  }

  :root [data-theme="system"] {
    --foreground: floralwhite;
    --background: #073b4c;
    --link: #ffd88a;
    --visited: #ffe9bd;
    --code-background: #002b36;
  }
}

:root [data-theme="dark"] {
  --foreground: floralwhite;
  --background: #073b4c;
  --link: #ffd88a;
  --visited: #ffe9bd;
  --code-background: #002b36;
}

:root [for="motion-switcher-option-system"]:after {
  content: " (normal)";
}

@media (prefers-reduced-motion) {
  :root [for="motion-switcher-option-system"]:after {
    content: " (reduced)";
  }

  :root [data-motion="system"] {
    --profile-picture-background-image: none;
    --clip-image-padding: 0;
    --social-media-animation: none;
    --aside-transition: none;
    --social-media-icon-hover-scale: 1;
  }
}

:root [data-motion="reduced"] {
  --profile-picture-background-image: none;
  --clip-image-padding: 0;
  --social-media-animation: none;
  --aside-transition: none;
  --social-media-icon-hover-scale: 1;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  padding-right: max((100% - 1920px) / 2, env(safe-area-inset-right), 2rem);
  padding-left: max((100% - 1920px) / 2, env(safe-area-inset-left), 2rem);
  font-family: Tinos, serif;
}

.content-headline, .settings-headline {
  border-bottom: solid var(--yellow);
  width: max-content;
}

.link:link {
  color: var(--link);
  text-decoration: none;
}

.link:visited {
  color: var(--visited);
}

.link:hover {
  text-decoration: underline;
}

.link--perma:link, .link--perma:visited {
  color: var(--foreground);
}

.link--cta {
  font-size: 2rem;
}

/*# sourceMappingURL=index.075a875b.css.map */
