@font-face {
  font-family: "Tinos";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.woff2")
      format("woff2"),
    url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.woff")
      format("woff"),
    url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.ttf")
      format("truetype"),
    url("./assets/fonts/tinos/tinos-v24-cyrillic_greek_latin-regular.svg#Tinos")
      format("svg");
}

@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/arimo/arimo-v27-latin-regular.eot");
  src: url("./assets/fonts/arimo/arimo-v27-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/arimo/arimo-v27-latin-regular.woff2") format("woff2"),
    url("./assets/fonts/arimo/arimo-v27-latin-regular.woff") format("woff"),
    url("./assets/fonts/arimo/arimo-v27-latin-regular.ttf") format("truetype"),
    url("./assets/fonts/arimo/arimo-v27-latin-regular.svg#Arimo") format("svg");
}

@font-face {
  font-family: "Cousine";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/cousine/cousine-v25-latin-regular.eot");
  src: url("./assets/fonts/cousine/cousine-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/cousine/cousine-v25-latin-regular.woff2")
      format("woff2"),
    url("./assets/fonts/cousine/cousine-v25-latin-regular.woff") format("woff"),
    url("./assets/fonts/cousine/cousine-v25-latin-regular.ttf")
      format("truetype"),
    url("./assets/fonts/cousine/cousine-v25-latin-regular.svg#Cousine")
      format("svg");
}
