@mixin normal {
  --profile-picture-background-image: url(/assets/background.svg);
  --clip-image-padding: 239px;
  --social-media-animation: dangling 1s;
  --aside-transition: transform 200ms;
  --social-media-icon-hover-scale: 1.1;
}

@mixin reduced {
  --profile-picture-background-image: none;
  --clip-image-padding: 0;
  --social-media-animation: none;
  --aside-transition: none;
  --social-media-icon-hover-scale: 1;
}
