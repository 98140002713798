main {
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
}

.paragraph {
  margin-left: 1rem;
  text-align: justify;
  font-family: "Arimo", sans-serif;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-top: 2rem;
  gap: 0.5rem;
  &__item {
    background-color: var(--foreground);
    color: var(--background);
    border-radius: 6px;
    padding: 0.2em 0.5rem;
    font-size: 0.8rem;
    border: solid var(--foreground);
  }
}

.download-button {
  background-color: var(--red);
  padding: 0.6rem;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  color: var(--foreground);
  text-decoration: none;
  margin-top: 2rem;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}
