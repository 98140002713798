@use "colors";
@use "motion";
@use "aside";
@use "header";
@use "main";
@use "footer";
@use "fonts";

:root {
  @include colors.light;
  [for="theme-switcher-option-system"] {
    &::after {
      content: " (light)";
    }
  }

  @media (prefers-color-scheme: dark) {
    [for="theme-switcher-option-system"] {
      &::after {
        content: " (dark)";
      }
    }
    [data-theme="system"] {
      @include colors.dark;
    }
  }
  [data-theme="dark"] {
    @include colors.dark;
  }

  @include motion.normal;
  [for="motion-switcher-option-system"] {
    &::after {
      content: " (normal)";
    }
  }

  @media (prefers-reduced-motion) {
    [for="motion-switcher-option-system"] {
      &::after {
        content: " (reduced)";
      }
    }
    [data-motion="system"] {
      @include motion.reduced;
    }
  }
  [data-motion="reduced"] {
    @include motion.reduced;
  }
}

body {
  font-family: "Tinos", serif;

  background-color: var(--background);
  color: var(--foreground);
  padding-right: calc(
    max((100% - 1920px) / 2, env(safe-area-inset-right), 2rem)
  );
  padding-left: calc(max((100% - 1920px) / 2, env(safe-area-inset-left), 2rem));
}

.content-headline,
.settings-headline {
  border-bottom: solid var(--yellow);
  width: max-content;
}

.link {
  &:link {
    text-decoration: none;
    color: var(--link);
  }
  &:visited {
    color: var(--visited);
  }
  &:hover {
    text-decoration: underline;
  }
  &--perma {
    &:link {
      color: var(--foreground);
    }
    &:visited {
      color: var(--foreground);
    }
  }
  &--cta {
    font-size: 2rem;
  }
}
