aside {
  position: fixed;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
  max-width: 400px;
  background-color: var(--blue);
  transform: translateX(-100%);
  transition: var(--aside-transition);
  color: var(--foreground-aside);
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: 1920px) {
    transform: translateX(0);
  }
}

.open {
  transform: translateX(0);
  .settings-toggle__icon {
    display: none;
    &--close {
      display: unset;
    }
  }
}

.settings-toggle {
  position: absolute;
  border: none;
  color: inherit;
  right: 0;
  top: 1em;
  transform: translateX(100%);
  background-color: var(--blue);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &__icon {
    height: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    &--close {
      display: none;
    }
  }
  @media (min-width: 1920px) {
    display: none;
  }
}

.setting-controls {
  font-family: "Arimo", sans-serif;
}
